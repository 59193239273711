import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect } from 'react';

import { CreateButton, CancelButton } from "../../Helpers";

const content_initial = []

export function ContentSection({ label }) {
  const [ content, setContent ] = useState(content_initial)

  return (<div className="p-3">
    <div className='md:flex justify-between'></div>
    <h2 className="text-gray-500 p-3">{label}</h2>
    <div className='flex justify-normal gap-2 flex-wrap '>
    {
      content.map(c=><ContentButton title={c.title} key={c.id} />)
    }
    
    <CreateNewForm content={content} setContent={setContent} label={label}/> 
     
    </div>
  </div>);
}

function ContentButton({ title }) {
  return (<button className="h-28 text-blue-400 bg-blue-50 hover:bg-gray-100 rounded-md text-center w-full md:w-80">
    {title}
  </button>);
}

function CreateNewButton({ label, setAddNew }) {
  return (<button onClick={()=>setAddNew(true)} className="h-28 text-gray-400 bg-gray-50 hover:bg-gray-100 rounded-md text-center w-full">
    <FontAwesomeIcon icon="fa-solid fa-circle-plus" /> {label}
  </button>);
}

function CreateNewForm({ content, setContent, label }){
  const [ addNew, setAddNew ] = useState(false)
  const [ newTitle, setNewTitle ] = useState('')
  const [ error, setError ] = useState(null)
  const formRef = useRef(null)
  

  function handleAddNew(){
    // check for existing content 
    setError(null)
    if(newTitle.trim() === ''){
      setError('Enter a title.')
    } 
    else{
      const exists = content.filter(e=> e.title.toLowerCase() === newTitle)
      if(exists.length === 0){
        setContent(content=>[...content, {id: content.length, title:newTitle}])
        setAddNew(false)
        setNewTitle('')
      }
      else{
        setError('This title already exists.')
      }
    }
  }

  function handleCancelNew(){
    setNewTitle('')
    setAddNew(false)
  }

  const handleClickOutside = (event) => {
    // Check if the click is outside of the form
    if (formRef.current && !formRef.current.contains(event.target)) {
      setAddNew(false); // Close the form
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (<div className='w-full md:w-80'>
    {
      addNew ? //

      <div ref={formRef} className='h-28 bg-gray-100 rounded-md w-full md:w-80 p-4 '>
        <input type='text' placeholder='Enter title' value={newTitle} onChange={e=>setNewTitle(e.target.value)} className='py-1 px-3 rounded-sm w-full' />
        { <p className='text-red-400 text-xs leading-1 pt-1 flex h-4'>{error}</p>}
        <div className=' flex gap-2'>
          <CreateButton onClick={handleAddNew} className='mt-2'/>
          <CancelButton onClick={handleCancelNew} className='mt-2' />
        </div>
      </div>
      :
      <CreateNewButton label={`Create ${label}`} setAddNew={setAddNew}/>
    }
  </div>)
}
