import { Outlet } from "react-router-dom";
import { SidebarNav } from "./SidebarNav";

export function MainApp({ currentEvent, setCurrentEvent, session, setSession, children }) {
  return (<>
    <div className="col-span-2 md:col-span-1 flex">
      <SidebarNav session={session} setSession={setSession} currentEvent={currentEvent} setCurrentEvent={setCurrentEvent} />
    </div>
    <div className="col-span-2 md:col-span-1 flex-auto md:pt-6 p-4">
      <Outlet/>
    </div>
  </>);
}
