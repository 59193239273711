import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export function NoPage(){
    return (<div className="container mx-auto p-4 font-bold">
        <h1 className='text-7xl text-red-700'><FontAwesomeIcon icon="fa-solid fa-link-slash" /></h1>
        <h2 className="text-2xl text-red-400"> Page not found.</h2>
        <p className='py-3'>You may be looking for a page that is not available right now.</p>

        <Link to='/'>
        <button className="w-auto p-3 text-gray-500 bg-gray-100 hover:bg-gray-200 rounded-md">
            <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Back to home
        </button>
        </Link>
    </div>)
}