export function NavBar() {
  return (
    <section className="bg-gray-50 nav-bar">
      <div className="container m-auto">
        <nav className="md:flex md:flex-row md:justify-between">
          <div className="col-span-2 md:col-span-1">
            <h2 className="text-2xl font-bold p-2">EvessHub</h2>
          </div>
        </nav>
      </div>
    </section>);
}
