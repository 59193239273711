import { useEffect, useState } from "react";
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from "./client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { NavBar } from "./pages/layout/NavBar";
import { MainApp } from "./pages/layout/MainApp";
import { NoPage } from "./pages/nopage";
import { Home } from "./pages/home/index";
import { Events } from "./pages/events";
import { Loader } from "./Helpers";

library.add(fas)

export const iosOnly = ()=>{
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
}

const authProviders = ['google']

function App() {
  const [session, setSession] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => { // session management
    setIsLoading(true)
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
    setTimeout(()=>{
      setIsLoading(false)
    },500)
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    document.title = "EvessHub"
  }, [])

  return (<div className="main-app">
    <NavBar/>
    <div className={`${session && 'md:flex'} container m-auto`} style={{ minHeight: 'calc(100vh - 100px)'}}>
      {
        isLoading ? 
        <div className="w-full">
          <Loader/>
        </div>
        :
        (
          session ? 
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainApp session={session} setSession={setSession}/>}>
                <Route index element={<Home />}/>
                <Route path="events/:uuid" element={<Events session={session}/>}/>
              </Route>
              <Route path="*" element={<NoPage />}/>
            </Routes>
          </BrowserRouter> :
          <div className="p-5 md:p-5 max-w-md m-auto mt-10">
            <h2 className="py-4 text-gray-500 text-center font-bold text-lg">Login</h2>
            <hr className="py-1"/>
          <Auth 
            supabaseClient={supabase} 
            appearance={{ theme: ThemeSupa }} 
            providers={authProviders}
            // providers={[]} //switch this comments when deploying to prod
          />
          </div>
        )
      }
      
    </div>
    <Footer />
  </div>);
}

function Footer(){
  return (
  <div className='bg-gray-800' style={{height: '60px'}}>
    <div className='container m-auto p-2'>
      <p className='text-gray-300 text-sm text-center'>
      <span className='text-xs'>EvessHub &bull; All rights reserved { new Date().getFullYear()}</span>
      <br/>
        A product of <a className='text-green-400 hover:text-green-500' href='https://bitslytech.com'>Bitsly I.T. Solutions.</a> 
      </p>
    </div>
  </div>)
}


export default App;
