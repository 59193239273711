
import { ContentSection } from "./ContentSection";
import { ContentLayout } from "../layout/ContentLayout";
import { useParams } from "react-router-dom";
export function Events({ reloadSideBar, session}){
  const { uuid } = useParams()
    return (<>
    <ContentLayout key={uuid} session={session} >
      <ContentSection label="Web Invitations" />
        <div className="p-3"><hr /></div>
      <ContentSection label="Guest Lists"/> 
    </ContentLayout>
    </>)
}