import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from "react-router-dom";
import { supabase } from '../../client'
import { Loader } from "../../Helpers";

export function ContentLayout({ session, children }){
  const [ currentEvent, setCurrentEvent ] = useState(null)
  const [ isDeleted, setIsDeleted ] = useState(false)
  const [ error, setError ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)
  const {uuid} = useParams()
  

  async function handleDeleteEvent(){
    if(window.confirm(`Are you sure you want to delete ${currentEvent.name} event?`)){

      const { data, error } = await supabase
        .from('events')
        .delete()
        .eq('id', currentEvent?.id);

      if (error) {
        console.error('Error deleting data:', error);
      } else {
        console.log('Data deleted successfully:', data);
        setIsDeleted(true)
      }
    }
  }

  useEffect(() => {
    async function getEventDetails(){
      const { data, error } = await supabase
      .from('events')
      .select('*')
      .eq('user_id', session.user.id)
      .eq('uuid', uuid)

      if(error){
        setError(error)
        setIsLoading(false)
        setCurrentEvent(undefined)
      }
      else{
        setCurrentEvent(data.slice().at(0))
        setIsLoading(false)
      }
    }

    setIsLoading(true)
    getEventDetails()
  }, [uuid, session.user.id])
  

  return (<div>
      {
        isDeleted ?
        <DeletedPrompt />
        : 
        (isLoading ?
          <Loader />
          :
          (currentEvent ? 
            <>
            <ContentHeading currentEvent={currentEvent} onDeleteEvent={handleDeleteEvent}/>
            {
              children
            }
            </>
            : // selected ID is not found for this user
            currentEvent === undefined &&
            <NotFound />
          )
        )
      }
  </div>)
}

function ContentHeading({ currentEvent, onDeleteEvent }){
  return (
    <div>
      <div className='flex justify-between gap-3'>
        <h1 className="text-2xl text-gray-600 px-4 font-bold ">{currentEvent?.name}</h1>
        <OptionsMenu onDeleteEvent={onDeleteEvent} />
      </div>
    </div>)
}

function NotFound(){
  return (<div className="p-10 text-gray-500 rounded-md border">
  <div className="flex items-center gap-3 my-3">
    <h1 className="text-5xl"><FontAwesomeIcon icon="fa-solid fa-file-circle-exclamation" /></h1>
    <h2 className="text-3xl">Event not found.</h2>
  </div>
  <p>You might not have access to this event or it has already been deleted.</p>
</div>)
}

function DeletedPrompt(){
  return (<div>
    <div className="flex gap-3 items-center text-green-800 py-3">
      <h1 className="text-3xl"><FontAwesomeIcon icon="fa-solid fa-circle-check" /></h1>
      <h2 className="text-2xl">Event has been deleted. </h2>
    </div>
    <Link to='/'>
      <button className="w-auto p-3 text-gray-500 bg-gray-100 hover:bg-gray-200 rounded-md">
        <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Back to home
      </button>
    </Link>
  </div>)
}


function OptionsMenu({ onDeleteEvent }){
  const [ openOptions, setOpenOptions ] = useState(false)
  const formRef = useRef(null);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setOpenOptions(false); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  return (<div ref={formRef} className="relative">
  <button onClick={()=>{setOpenOptions(o=>!o)}} className='p-2 bg-gray-100 hover:bg-gray-200 rounded-md px-3'>
    <FontAwesomeIcon icon="fa-solid fa-gear" />
  </button>
  {
    openOptions && (
      <div className="absolute w-40 bg-gray-50 rounded-md right-0 mt-1 flex flex-col overflow-hidden text-gray-700 drop-shadow-lg">
        <button className="p-2 bg-gray-100 hover:bg-gray-200 flex flex-row items-center justify-start gap-2 border-b text-left">
          <FontAwesomeIcon icon="fa-solid fa-check" />
          <span>Option 1</span>
        </button>
        <button className="p-2 bg-gray-100 hover:bg-gray-200 flex flex-row items-center justify-start gap-2 border-b text-left">
          <FontAwesomeIcon icon="fa-solid fa-check" />
          <span>Option 2</span>
        </button>
        <button className="p-2 bg-gray-100 hover:bg-gray-200 flex flex-row items-center justify-start gap-2 border-b text-left">
          <FontAwesomeIcon icon="fa-solid fa-check" />
          <span>Option 3</span>
        </button>
        <button onClick={e=>onDeleteEvent()} className="p-2 bg-red-300 hover:bg-red-400 text-red-800 flex flex-row items-center justify-start gap-2 text-left">
          <FontAwesomeIcon icon="fa-solid fa-trash" />
          <span>Delete event</span>
        </button>
      </div>
    )
  }
</div>)
}