
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as LoadingSVG } from './loading.svg'

export function CreateButton({ className, onClick}){
    return (<button onClick={()=>onClick()} className={`font-normal flex-auto p-1 bg-blue-300 hover:bg-blue-400 text-gray-50 rounded-sm text-sm ${className}`}>
    <FontAwesomeIcon icon="fa-solid fa-circle-plus" /> Create
  </button>)
  }

export function CancelButton({ className, onClick}){
    return (<button onClick={()=>onClick()} className={`font-normal flex-auto p-1 bg-gray-300 hover:bg-gray-400 text-gray-50 rounded-sm text-sm ${className}`}>
      <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Cancel
    </button>)
  }

export function UpperCaseFirstLetter(string){
    return `${string.trim().substring(0,1).toUpperCase()}${string.trim().substring(1)}`
}

export function getAvatar(name){
  return `https://api.dicebear.com/9.x/initials/svg?seed=${name}`
}

export function Loader(){
  return (<div className="p-3 text-gray-500 py-10">
  <p className="text-center">LOADING</p>
  <div>
    <LoadingSVG className="w-10 m-auto"/>
  </div>
</div>)
}

export function ButtonLoaders(){
  return <LoadingSVG className="h-7 m-auto"/>
}