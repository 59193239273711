import { useEffect, useState, useRef } from "react";
import { supabase } from "../../client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateButton, CancelButton, UpperCaseFirstLetter } from "../../Helpers";
import { iosOnly } from "../../App";
import { getAvatar } from "../../Helpers"
import { Link, useParams } from "react-router-dom";
import { ButtonLoaders } from "../../Helpers";

export function SidebarNav({ setSession, session }) {
  const [ events, setEvents ] = useState([])
  const [ profileClicked, setProfileClicked ] = useState(false)
  const [ refetch, setRefetch ] = useState(true)
  const [ error, setError ] = useState(null)
  const [ successMessage, setSuccessMessage ] = useState([])
  const { uuid } = useParams()

  async function createEvent(name,date, callback=()=>{}){
    const { error } = await supabase
      .from('events')
      .insert([
        {
          user_id: session?.user?.id,
          name: UpperCaseFirstLetter(name), 
          date: date
        }
      ]);
    if (error) {
      setError(`Error creating new event. ${error}`)
    } else {
      setSuccessMessage(`New event created successfully.`)
      setRefetch(true)
      callback()
    }
  }

  useEffect(() => {
    setRefetch(true)
  }, [uuid])
  

  useEffect(() => {
    async function getEvents(){
      setError(null)
      let { data, error } = await supabase
      .from('events')
      .select("*")
      .eq('user_id', session?.user?.id)
  
      if(error){
        setError(error)
      }
      else{
        setEvents([...data])
      }
    }

    if(refetch){
      getEvents()
    }
    setRefetch(false)
  }, [refetch, session?.user?.id])
  


  const name = session.user?.user_metadata?.name || session.user.email;
  const image = session.user?.user_metadata?.avatar_url || getAvatar(name)

  const profileData = {
    name,
    image
  }

  async function signOut(callback=()=>{}) {
    await supabase.auth.signOut();
    callback()
  }

  return (
    <div className="w-full md:w-60 font-thin">

      <div style={{ height: 20 }}></div>
      <div className="p-2 flex flex-col gap-2">
        <button title={profileData.name} onClick={()=>setProfileClicked(profileClicked=> !profileClicked)} className="nav-profile py-2 px-4 flex gap-2 items-center hover:bg-blue-200 justify-start w-full bg-blue-100 rounded-md">
          <img src={profileData.image} alt={profileData.name} className="h-10 w-10 rounded-full"/>
          <span className="block font-normal text-left text-ellipsis whitespace-nowrap overflow-hidden h-6">{profileData.name}</span>
        </button>
        {
          profileClicked && 
          <button onClick={()=>signOut(()=>
          setSession(null))} className="nav-profile py-2 px-4 flex gap-2 items-center hover:bg-gray-200 justify-start w-full bg-gray-100 rounded-md">
            <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /> Logout
          </button>
        }
      </div>
      <SideBarNavItem createEvent={createEvent} profileData={profileData} events={events} setEvents={setEvents} >
        <FontAwesomeIcon icon="fa-solid fa-folder" /> &nbsp; Events
      </SideBarNavItem>
    </div>);
}
function SideBarNavItem({ createEvent, profileData, events, setEvents, children }) {
  const [isOpen, setIsOpen] = useState(true);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  return (<>
    <button onClick={e => handleClick()} className="mt-5 w-full py-1 px-3 text-left hover:bg-gray-50 flex justify-between items-center"><span>{children}</span> <FontAwesomeIcon className="text-gray-400 text-sm" icon={`fa-solid ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`} /></button>

    {isOpen && <SideBarContentList createEvent={createEvent} profileData={profileData} events={events} setEvents={setEvents}  />}
  </>);
}

function SideBarContentList({ createEvent, events }) {
  const [ addEvent, setAddEvent ] = useState(false)
  const [ newTitle, setNewTitle ] = useState('')
  const [ newDate, setNewDate ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState('')
  const { uuid } = useParams()
  const formRef = useRef(null)
  

  function handleAddEvent(){
    setError('')
    if(newTitle.trim() === ''){
      setError('Enter a title.')
    }
    else if(newDate.trim() === ''){
      setError('Select a date of event.')
    }
    else{
      const exists = events.slice().filter(e=> e.name === newTitle)
      if(exists.length === 0){
        setIsLoading(true)
        createEvent(newTitle,newDate, ()=>{
          handleCancelNew()
        })
      }
      else{
        setError('The title already exists.')
      }
    }
  }

  function handleCancelNew(){
    setNewTitle('')
    setNewDate('')
    setAddEvent(false)
    setError('')
    setIsLoading(false)
  }

  const handleClickOutside = (event) => {
    // Check if the click is outside of the form
    if (formRef.current && !formRef.current.contains(event.target)) {
      setAddEvent(false); // Close the form
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (<div className="py-1 flex flex-col gap-2">
    {events.map(e => {
      const isActive = e.uuid === uuid;
      return (<Link to={`/events/${e.uuid}`} key={e.id} >
        <button className={`pl-6 w-full py-1 px-3 text-left ${isActive ? 'bg-blue-100 hover:bg-blue-50' : 'hover:bg-gray-50'}  flex justify-start gap-3 items-center`}>
          <img src={`${getAvatar(e.name)}`} alt={e.name} className="h-10 rounded-md"/> {e.name}
        </button>
      </Link>);
    }
    )}
    <div className="p-3">
    {
      addEvent ? 
        <div ref={formRef} className="p-3 rounded-md bg-gray-100 flex flex-col gap-2 font-normal">
          <div className="flex flex-col">
            <input value={newTitle} onChange={e=>setNewTitle(e.target.value)} className="py-1 px-2 rounded-sm" type="text" placeholder="Enter title" />
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Date of event</label>
            <label htmlFor="date" className="bg-white rounded-sm">
              { 
              iosOnly() &&
              (!newDate && <span className="md:hidden">mm/dd/yyyy</span>)}
            <input id="date" value={newDate} onChange={e=>setNewDate(e.target.value)} className="py-1 px-2 rounded-sm w-full bg-white " type="date" placeholder="Date of event" />
            </label>

            <p className='text-red-400 text-xs leading-1 pt-1 flex h-4'>{error}</p>
          </div>
          {
            isLoading ?
              <ButtonLoaders />
            : <div className=' flex gap-2'>
              <CreateButton onClick={handleAddEvent} >
                <FontAwesomeIcon icon="fa-solid fa-circle-plus" /> Create
              </CreateButton>
              <CancelButton onClick={handleCancelNew}>
                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Cancel
              </CancelButton>
            </div>

          }
        </div>
      : 
        <button onClick={()=>setAddEvent(true)} className="pl-6 py-4 w-full px-3 text-left hover:bg-gray-200 bg-gray-100 flex justify-start gap-3 rounded-md">
          <span className="text-sm"><FontAwesomeIcon icon="fa-solid fa-plus" /></span> Create new event
        </button>
    }
    </div>
  </div>);
}
